// Generated by Framer (12ecc5e)

import { addFonts, cx, CycleVariantState, SVG, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const serializationHash = "framer-vmr4w"

const variantClassNames = {hFlb4u70N: "framer-v-kdqilh"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "hFlb4u70N", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-kdqilh", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"hFlb4u70N"} ref={ref ?? ref1} style={{...style}}><SVG className={"framer-1sv3nr0"} data-framer-name={"Graphic"} fill={"black"} intrinsicHeight={352} intrinsicWidth={242} layoutDependency={layoutDependency} layoutId={"q1II0lgmp"} svg={"<svg width=\"242\" height=\"352\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M.8 289.206V90.796L121.899.97l119.94 91.033v197.204H219.19V104.06l-97.478-73.987-98.263 72.925v186.208H.801Z\" fill=\"#EDEDE8\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M58.607 351.588v-229.41l63.352-46.985L184.03 122.3v107.11l-51.016 37.669-22.586 16.932v-107.11h22.648v60.692l28.305-20.385V134.37l-39.588-30.063-40.537 30.073-.093 217.208H58.607Z\" fill=\"#EDEDE8\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M.8 328.977h106.357l76.874-57.023.247 28.826-.247 28.197h57.807v22.611h-80.456v-33.886l-45.606 33.886H.801v-22.611Z\" fill=\"#EDEDE8\"/></svg>"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-vmr4w.framer-1qtql7x, .framer-vmr4w .framer-1qtql7x { display: block; }", ".framer-vmr4w.framer-kdqilh { height: 352px; overflow: hidden; position: relative; width: 242px; }", ".framer-vmr4w .framer-1sv3nr0 { aspect-ratio: 0.6875 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 352px); left: 0px; position: absolute; right: 0px; top: 0px; z-index: 1; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 352
 * @framerIntrinsicWidth 242
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerQNwhYwdlP: React.ComponentType<Props> = withCSS(Component, css, "framer-vmr4w") as typeof Component;
export default FramerQNwhYwdlP;

FramerQNwhYwdlP.displayName = "Logo Graphic";

FramerQNwhYwdlP.defaultProps = {height: 352, width: 242};

addFonts(FramerQNwhYwdlP, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})